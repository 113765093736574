const networks = [
  { value: "eth-mainnet", name: "Ethereum", explorer: "https://etherscan.io/address/$ADDRESS$", currency: 'ETH'},
  { value: "polygon-mainnet", name: "Polygon", explorer: "https://polygonscan.com/address/$ADDRESS$", currency: "MATIC" },
  { value: "bsc-mainnet", name: "Binance BSC", explorer: "https://bscscan.com/address/$ADDRESS$", currency: "BNB" },

  { value: "eth-ropsten", name: "ETH Ropsten", explorer: "https://ropsten.etherscan.io/address/$ADDRESS$", currency: 'ETH' },
  { value: "polygon-mumbai", name: "Poly. Mumbai", explorer: "https://mumbai.polygonscan.com/address/$ADDRESS$", currency: 'MATIC' },

  /*
  { value: "eth-kovan", name: "Kovan", explorer: "https://kovan.etherscan.io/address/$ADDRESS$"  },
  { value: "eth-rinkeby", name: "Rinkeby", explorer: "https://rinkeby.etherscan.io/address/$ADDRESS$"  },
  { value: "eth-goerli", name: "Goerli", explorer: "https://goerli.etherscan.io/address/$ADDRESS$"  },
  { value: "bsc-testnet", name: "Binance BSC Testnet", explorer: "https://testnet.bscscan.com/address/$ADDRESS$" },
  */
];

export default networks;